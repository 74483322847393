const fetchUsersWithAccess = async () => {

    const items: Array<UserWithAccess> = [
        { userId: "DGates", firstName: "David", lastName: "Gates", emailAddress: "david.gates@evicore.com", status: "Pending" },
        { userId: "BPeters", firstName: "Belinda", lastName: "Peters", emailAddress: "belinda.peters@evicore.com", status: "Pending" },
        { userId: "CBrien", firstName: "Conan", lastName: "OBrien", emailAddress: "conan.obrien@evicore.com", status: "Accepted" },
        { userId: "TGonz", firstName: "Tyler", lastName: "Gonzales", emailAddress: "tyler.gonzales@evicore.com", status: "Accepted" },
    ];
    return items;

}

const SharedWorklistService = {
    fetchUsersWithAccess
  };
  
  export default SharedWorklistService;