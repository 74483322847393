/* eslint-disable @typescript-eslint/no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faUserLock, faNotesMedical, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import * as styles from './headermenu.module.css';
import { UserAccessContext } from '../../contexts/userAccessContext';


interface HeaderMenuProps {
    userAuthorized?: boolean
}


const HeaderMenu: React.FC<HeaderMenuProps> = ({ userAuthorized = false }) => {
    const { isUserAccessToggled, toggleUserAccess } = useContext(UserAccessContext);
    const handleUserAccessClick = () => {
        toggleUserAccess();
    }
    
    return (
        <div>
            {userAuthorized ? (
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item" hidden={process.env.name != 'dev'}>
                        <Link to={'/requestAnAuthorization'} id='requestAnAuthNavLink' className={`nav-link ${styles.menuoption}`} data-testid="requestAnAuthLink">
                            <FontAwesomeIcon icon={faNotesMedical} className="pr-2" />
                            Request An Authorization
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/'} id='worklistLink' className={`nav-link ${styles.menuoption}`} data-testid="worklistLink">
                            <FontAwesomeIcon icon={faClipboardList} className="pr-2" />
                            Worklist
                        </Link>
                    </li>                    
                    <li className="nav-item" hidden={process.env.name != 'dev'}>
                        <Link to={`${process.env.evicore_url}`} className={`nav-link ${styles.menuoption}`} data-testid="authLookupLink">
                            <FontAwesomeIcon icon={faMagnifyingGlass} className="pr-2" />
                            Auth Lookup
                        </Link>
                    </li>
                    <li className="nav-item" hidden={process.env.name != 'dev'}>
                        <button onClick={handleUserAccessClick} type="button" className={`nav-link ${styles.menuoption}`} data-testid="showUserAccessButton">
                            <FontAwesomeIcon icon={faUserLock} className="pr-2" />
                            User Access
                        </button>
                    </li>
                </ul>
            ) : <div></div>
            }

        </div>
    );
};

export default HeaderMenu;