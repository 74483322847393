import React, { useEffect } from 'react';
import { ICryptography } from "./ICryptography";
import { useSearchParams } from 'react-router-dom';

type CallBackServiceProps = {
    cryptoGraphy:ICryptography
};

//In the future this should be wrapped in a suspense component that contains a loading spinner
//We should also load some sort of backgound in
const InitiateSSO: React.FC<CallBackServiceProps> = (props: CallBackServiceProps) => {

    const [searchParams] = useSearchParams();
    const redirect = searchParams.get('redirect');
    
    useEffect(() => {
        // Generate Verifier and store it on local storage
        const verifier = props.cryptoGraphy.verifier();
        localStorage.setItem("codeVerifier", verifier);

        // Generate callenge and redirect with it 
        const challengePromise = async() => props.cryptoGraphy.challenge(verifier);
        challengePromise().then((challenge) => window.location.assign(`${redirect}?challenge=${challenge}`));  
    }, [])

    return (
        <div>code page</div>
    );
};

export default InitiateSSO;
