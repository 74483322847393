import { GiveUserAccessAction, UserAcccessActionStatus } from "../../interfaces/GiveUserAccess";
import GiveUserAccessService from "../../services/giveUserAccess.service";
import React, { useState } from 'react';
import SharedWorklistAccessModal from "../User/sharedworklistaccessmodal.component"

const GiveUserAccess: React.FC = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleUserAccessClick = () => {

        //***pass test data until there is real data flowing***
        const userAccessAction: GiveUserAccessAction = {
            userId: 'testUser',
            emailAddress: 'email@email.com',
            userWithAccess: {
                status:UserAcccessActionStatus.ACCEPTED,
                userId: 'userwithaccessId',
                lastName: 'userwithaccessLastName',
                firstName: 'userwithaccessFirstName',
                emailAddress: 'userwithaccess@test.org'
            }
        }
        GiveUserAccessService.setUserAccessAction(userAccessAction);

    }

    const handleViewListClick = () => {
        setModalIsOpen(true);
    }
    return (
        <div>
            <div className="card-body" data-testid="giveuseraccess">
                <div className="card-header bg-transparent border-bottom-0 pb-0 pt-0 pr-0 pl-0">
                    <div className="alert alert-dismissible alert-success">
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        <p className="mb-0">David Gates will have access to your worklist</p>
                    </div>
                    <div className="align-items-center d-flex justify-content-between">
                        <h6 className="card-title mb-0">Give access to your worklist</h6>
                    </div>
                    <div className="collapse" id="collapseAddNurse"></div>
                    <div>
                        <div>
                            <div className="pt-3 ">
                                <p>Use this form to give users access to your worklist</p>
                                <div className="input-group mt-3 pb-3">
                                    <input type="text" className="form-control" id="userName" placeholder="User ID" name="userID" data-testid="userIdInput" />
                                    <input type="text" className="form-control" id="email" placeholder="Email" name="email" data-testid="emailInput" />
                                    <div className="input-group-append">
                                        <button className="btn btn-sm btn-tertiary fa-pull-right" data-toggle="modal" data-target="#legalModal" aria-expanded="true" onClick={handleUserAccessClick} > <i className="fa fa-fw fa-plus-circle text-primary"></i> Allow access
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="alert alert-dismissible alert-info">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                    <p className="mb-0">35 people have access to your worklist. <button className="btn btn-sm btn-tertiary mx-2 py-1" data-toggle="modal" data-target="#haveaccessModal" aria-expanded="true" onClick={handleViewListClick} > <i className="fa fa-fw fa-plus-circle text-primary"></i> View List
                    </button></p>
                </div>
            </div>
            <div data-testid="modalOpenElement">
                {modalIsOpen && (
                    <SharedWorklistAccessModal
                        onClose={() => setModalIsOpen(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default GiveUserAccess;