import { ICryptography } from "./ICryptography";

export default class Cryptography implements ICryptography
{
    verifier(): string {
        //16 8 bit integers in an array
        const eightBitIntArray = new Uint8Array(32);

        const randomEightBitIntArray = window.crypto.getRandomValues(eightBitIntArray);

        let randomString: string = "";

        //Each member of the array represents a unique character and can be converted and added onto the string
        randomEightBitIntArray.forEach((eightBitInt) => {
            randomString += String.fromCharCode(eightBitInt);
        });

        //Base 64 encode so that this can be sent as a url parameter
        const base64EncodedString = btoa(randomString);

        return base64EncodedString;
    }

    async challenge(verifier: string): Promise<string> {

        const encoder = new TextEncoder();
        const buffer = encoder.encode(verifier);
        const arrayBuffer = await window.crypto.subtle.digest("SHA-512", buffer);
    
        let str = "";
        const bytes = new Uint8Array(arrayBuffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            str += String.fromCharCode(bytes[i]);
        }
        
        const challenge = btoa(str)
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");
    
        return challenge;
    }
}