// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DM4gh5rPzBnycWxYqzVw {
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

button:focus {
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/headermenu.module.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".menuoption {\n    background-color: rgba(0, 0, 0, 0);\n    border: none;\n}\n\nbutton:focus {\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
export var menuoption = `DM4gh5rPzBnycWxYqzVw`;
export default ___CSS_LOADER_EXPORT___;
