import React from 'react';
import Header from './components/Header/header.component';
import RequestDashboard from './modules/RequestDashboard/RequestDashboard';
import { TimeoutProvider } from './components/Timeout/timeoutprovider.component';

const App: React.FC = () => {
    return (
        <TimeoutProvider timeoutSeconds={Number(process.env.session_timeout)}>
            <div>
                <Header />
                <main>
                    <RequestDashboard />
                </main>
            </div>
        </TimeoutProvider>
    );
};

export default App;