import { Link } from "react-router-dom";
import Header from "../Header/header.component";
import * as styles from './timeoutpage.module.css';

const TimeoutPage: React.FC = () => {


    return (

        <div>
            <Header showUserData={false}/>
            <h3 className="pl-3 pt-2">Session Timeout</h3>
            <p className="pl-3">Your session has timed out. Please return to the login page to start a new session.</p>
            <Link to={`${process.env.evicore_url}`} className="pl-3" data-testid="returnToLoginLink">
                <button type="button" data-testid='returnToLoginButton' className={`btn btn-sm btn-primary py-0 ${styles.returnToLoginButton}`} >
                    Return to Login
                </button>
            </Link>
        </div>
    );
};

export default TimeoutPage;
