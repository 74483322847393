import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Auth from './components/Auth/Auth';
import App from './App';
import InitiateSSO from './components/Auth/InitiateSSO';
import Cryptography from './components/Auth/Cryptography';
import InitiateAuthLookup from './components/Auth/InitiateAuthLookup';
import TimeoutPage from './components/Timeout/timeoutpage.component';
import UnauthorizedPage from './components/Verification/unauthorizedpage.component';
import IdleSessionHandler from './components/Timeout/idlesessionhandler.component';
import { AuthGuard } from './guards/authguard';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './services/appInsights.service';
import { TokenService } from './services/token.service';
import RequestAnAuth from './components/RequestAnAuth/requestAnAuth.component';
import { RequestAnAuthService } from './services/requestAnAuthService';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(

    <AppInsightsContext.Provider value={reactPlugin}>
      <BrowserRouter>
        <Routes>
          <Route path="/auth" element={<React.StrictMode><Auth /></React.StrictMode>} />
          <Route path="/sso" element={<React.StrictMode><InitiateSSO cryptoGraphy={new Cryptography()} /></React.StrictMode>} />
          <Route path="/callback/:authId" element={<InitiateAuthLookup tokenService={new TokenService()} />} />
          <Route path="/" element={
            <React.StrictMode>
              <AuthGuard>
                <IdleSessionHandler tokenService={new TokenService()} 
                timeout={Number(process.env.timeout_milliseconds)}
                promptBeforeIdle={Number(process.env.prompt_before_idle_milliseconds)} 
                refreshTokenIntervalTime={Number(process.env.refresh_token_interval_time_milliseconds)}>
                  <App />
                </IdleSessionHandler>
              </AuthGuard>
            </React.StrictMode>
          } />
          <Route path="/timeoutpage" element={<React.StrictMode><TimeoutPage /></React.StrictMode>} />
          <Route path="/unauthorizedpage" element={<React.StrictMode><UnauthorizedPage /></React.StrictMode>} />
          <Route path="/requestAnAuthorization" element=
          {
            <React.StrictMode>
              <AuthGuard>
                <IdleSessionHandler tokenService={new TokenService()} 
                timeout={Number(process.env.timeout_milliseconds)}
                promptBeforeIdle={Number(process.env.prompt_before_idle_milliseconds)} 
                refreshTokenIntervalTime={Number(process.env.refresh_token_interval_time_milliseconds)}>
                  <RequestAnAuth requestAnAuthService={new RequestAnAuthService()} />
                </IdleSessionHandler>
              </AuthGuard>
            </React.StrictMode>
          } />
        </Routes>
      </BrowserRouter>
    </AppInsightsContext.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();