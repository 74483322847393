import React from 'react';
import Header from './components/Header/header.component';
import RequestDashboard from './modules/RequestDashboard/RequestDashboard';
import { UserAccessProvider } from './contexts/userAccessContext';


const App: React.FC = () => {
    return (
        <div>
            <UserAccessProvider>
                <Header />               
                <main>
                    <RequestDashboard />
                </main>
            </UserAccessProvider>
        </div>
    );
};

export default App;