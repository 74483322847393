import { useContext } from 'react';
import GiveUserAccess from './giveuseraccess.component';
import * as styles from './useraccess.module.css';
import { UserAccessContext } from '../../contexts/userAccessContext';


const UserAccess: React.FC = () => {
    const { isUserAccessToggled } = useContext(UserAccessContext);

    return (
        <div className={`row ${styles.useraccessBackground} ${styles.useraccessContainer} ${isUserAccessToggled ? "" : "collapse"}`}>
            <div className={`col-6 ${styles.useraccessCardGive}`}>
                <div className='card h-100'>
                    <GiveUserAccess />
                </div>
            </div>
        </div>
    );
};

export default UserAccess;