import React, { useEffect, useState } from 'react';
import * as styles from './header.module.css';
import logo from '../../assets/images/EP-Logo_sm.png';

interface HeaderProps {
  showUserData?: boolean
}

const Header: React.FC<HeaderProps> = ({ showUserData = true }) => {
  const [firstName, setfirstName] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {

    const storedFirstName = sessionStorage.getItem('firstName');
    if (storedFirstName) {
      setfirstName(storedFirstName);
    } else {
      setError('First name not found in session storage');
    }

  }, []);

  return (
    <div className={styles.stickyTop}>
      <nav
        className={`navbar navbar-expand-lg navbar-dark pl-0 py-0 ${styles.navbarTop}`}
      >
        <div className="p-3 mr-3">
          <img src={logo} height="60px" alt="Logo" />
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarColor02"
          aria-controls="navbarColor02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarColor02">
          {showUserData ? (
            error ? (
              <span className="navbar-brand">Failed to load</span>
            ) : (
              <span className="navbar-brand">Hello, {firstName}</span>
            )
          ) : <span></span>
          }
        </div>
      </nav>
      <nav
        className={`navbar navbar-expand-lg navbar-dark mb-3 ${styles.navbarBottom}`}
        id="anchorbar"
      >
        <div className="collapse navbar-collapse" id="navbarColor03"></div>
        <nav className="navbar-expand-lg float-right m-0 pr-0"></nav>
      </nav>
    </div>
  );
};

export default Header;
