export const revokeSession = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  if (accessToken) {
    try {
      const response = await fetch(`${process.env.api_url}/User/Session`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${accessToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      sessionStorage.removeItem('accessToken');
      const data: ApiResponse = await response.json();
      return data;
    } catch {
      throw new Error('Error Revoking token');
    }  
  }
};
