// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GY5rh8YhM4Tl5c484hlJ {
    background-color: #008f83;
  }

  .B1Xayw9iwY1Em6PINS9K {
    background-color: #008f83;
  }

  .PPppien9rADu3OTF3DoY{
    padding-left:30px;
    padding-right: 5px;
    padding-top: 15px;
    padding-bottom:15px;
  }
  .aSq9RKxsZeQTE7YjCcGm{
    padding-top: 15px;
    padding-bottom:15px;
    padding-left:5px;
    padding-right: 15px;
  }

  .fS3OyLSQM5VMlwz_jm9O {
    margin-right: auto;
  }`, "",{"version":3,"sources":["webpack://./src/components/User/useraccess.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;EACrB;EACA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":[".useraccessBackground {\n    background-color: #008f83;\n  }\n\n  .navbarBottom {\n    background-color: #008f83;\n  }\n\n  .useraccessCardGive{\n    padding-left:30px;\n    padding-right: 5px;\n    padding-top: 15px;\n    padding-bottom:15px;\n  }\n  .useraccessCardRequest{\n    padding-top: 15px;\n    padding-bottom:15px;\n    padding-left:5px;\n    padding-right: 15px;\n  }\n\n  .useraccessContainer {\n    margin-right: auto;\n  }"],"sourceRoot":""}]);
// Exports
export var useraccessBackground = `GY5rh8YhM4Tl5c484hlJ`;
export var navbarBottom = `B1Xayw9iwY1Em6PINS9K`;
export var useraccessCardGive = `PPppien9rADu3OTF3DoY`;
export var useraccessCardRequest = `aSq9RKxsZeQTE7YjCcGm`;
export var useraccessContainer = `fS3OyLSQM5VMlwz_jm9O`;
export default ___CSS_LOADER_EXPORT___;
