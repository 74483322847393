import { useSearchParams } from "react-router-dom";
import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//In the future this should be wrapped in a suspense component that contains a loading spinner
//We should also load some sort of backgound in
const Auth: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const code = searchParams.get('code');


    useEffect(() => {
        const codeVerifier = window.localStorage.getItem('codeVerifier');

        const options = {
            method: 'Post',
            body: JSON.stringify({
                codeVerifier: codeVerifier,
                code: code
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        };

        const result = fetch(`${process.env.api_url}/User/session/hash`, options);

        result.then(async res => {
            if (res.ok) {
                const data = await res.json();
                sessionStorage.setItem('accessToken', data.hash);
                sessionStorage.setItem('firstName', data.firstName);
                window.localStorage.removeItem("codeVerifier")
                navigate('/');
            }
            else {
                navigate('/unauthorizedpage');
            }
        });

    }, [searchParams])

    return (
        <></>
    );
};

export default Auth;
