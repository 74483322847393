// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O4BqTUi_njlqFpT577jl {
    padding-right: 17px;
    display: block;

}

.qM2oH30Wg3e8x3ild0eg {
    background-image: linear-gradient(to bottom, #ffffff, #f9fafb);
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}`, "",{"version":3,"sources":["webpack://./src/components/User/sharedworklistaccessmodal.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc;;AAElB;;AAEA;IACI,8DAA8D;IAC9D,kCAAkC;IAClC,mCAAmC;AACvC","sourcesContent":[".modalShow {\n    padding-right: 17px;\n    display: block;\n\n}\n\n.footerStyle {\n    background-image: linear-gradient(to bottom, #ffffff, #f9fafb);\n    border-bottom-left-radius: 0.25rem;\n    border-bottom-right-radius: 0.25rem;\n}"],"sourceRoot":""}]);
// Exports
export var modalShow = `O4BqTUi_njlqFpT577jl`;
export var footerStyle = `qM2oH30Wg3e8x3ild0eg`;
export default ___CSS_LOADER_EXPORT___;
