/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, createContext, useState } from 'react';

interface UserAccessContextType {
  isUserAccessToggled: boolean;
  toggleUserAccess: () => void;
  
}

const UserAccessContext = createContext<UserAccessContextType>({
  isUserAccessToggled: false,
  toggleUserAccess: () => {},
});

const UserAccessProvider: React.FC<{ children: ReactNode }> = ({ children }:any) => {
  const [isUserAccessToggled, setIsToggled] = useState(false);

  const toggleUserAccess = () => {
    setIsToggled(!isUserAccessToggled);
  };

  return (
    <UserAccessContext.Provider value={{ isUserAccessToggled, toggleUserAccess }}>
      {children}
    </UserAccessContext.Provider>
  );
};

export { UserAccessContext, UserAccessProvider };