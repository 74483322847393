// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._I9rY6DUhps8fWbVJf0L {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Timeout/timeoutpage.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd","sourcesContent":[".returnToLoginButton {\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
export var returnToLoginButton = `_I9rY6DUhps8fWbVJf0L`;
export default ___CSS_LOADER_EXPORT___;
