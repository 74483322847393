import { Suspense, lazy } from 'react';
import Loading from '../../components/Loading/loading.component';
const Worklist = lazy(() => import( '../../components/Worklist/worklist.component'));
const RequestDashboard = () => {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Worklist />
      </Suspense>
    </>
  );
};
export default RequestDashboard;
