
interface GiveUserAccessAction {
    userId: string;
    emailAddress: string;
    userWithAccess: UserWithAccess;
}

enum UserAcccessActionStatus {
    PENDING = 0,
    ACCEPTED = 1,
    DENIED = 2,
    REJECTED = 3,
}
interface UserWithAccess {
    userId: string;
    status: UserAcccessActionStatus;
    lastName: string;
    firstName: string;
    emailAddress: string;
}

export { UserAcccessActionStatus };
export type { GiveUserAccessAction, UserWithAccess };
