// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c3Rq1KgxA4xzlsEBAG7M {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(40px, 0px, 0px);
}

.d42eFeBG8JhloRDPyqC4 {
  background-image: linear-gradient(to bottom, #ffffff, #f9fafb);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.R10vUk2N0vV9cmnudaJn {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Worklist/worklist.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,QAAQ;EACR,SAAS;EACT,sCAAsC;AACxC;;AAEA;EACE,8DAA8D;EAC9D,kCAAkC;EAClC,mCAAmC;AACrC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".absolutePosition {\n  position: absolute;\n  will-change: transform;\n  top: 0px;\n  left: 0px;\n  transform: translate3d(40px, 0px, 0px);\n}\n\n.paginationFooter {\n  background-image: linear-gradient(to bottom, #ffffff, #f9fafb);\n  border-bottom-left-radius: 0.25rem;\n  border-bottom-right-radius: 0.25rem;\n}\n\n.navLink {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export var absolutePosition = `c3Rq1KgxA4xzlsEBAG7M`;
export var paginationFooter = `d42eFeBG8JhloRDPyqC4`;
export var navLink = `R10vUk2N0vV9cmnudaJn`;
export default ___CSS_LOADER_EXPORT___;
