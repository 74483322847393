import { GiveUserAccessAction } from "../interfaces/GiveUserAccess";

const setUserAccessAction = async (userAccessAction: GiveUserAccessAction) => {

    const accessToken = sessionStorage.getItem('accessToken');
    if (!accessToken) {
        throw new Error('Error retrieving Access Token');
    }
    try {
        const response = await fetch(`${process.env.api_url}/UserAccess`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${accessToken}`,
            },
            body: JSON.stringify({
                userId: userAccessAction.userId,
                emailAddress: userAccessAction.emailAddress,
                userWithAccess: {
                    status: userAccessAction.userWithAccess.status,
                    lastName: userAccessAction.userWithAccess.lastName,
                    firstName: userAccessAction.userWithAccess.firstName,
                    emailAddress: userAccessAction.userWithAccess.emailAddress,
                    userId: userAccessAction.userWithAccess.userId,
                }

            }),

        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return;
    } catch {
        throw new Error('Error Saving User Access Action');
    }
}

const GiveUserAccessService = {
    setUserAccessAction
};

export default GiveUserAccessService;
