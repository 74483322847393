import React, { useEffect, useState } from 'react';
import Header from '../Header/header.component';
import { IRequestAnAuthService, RequestAnAuthApiResponse } from '../../interfaces/IRequestAnAuthService';
import { appInsights } from "../../services/appInsights.service";

interface RequestAnAuthProps {
  requestAnAuthService: IRequestAnAuthService;
}

const RequestAnAuth: React.FC<RequestAnAuthProps> = (props) => {

  const [selectedOptionInsurer, setSelectedOptionInsurer] = useState("");
  const [selectedOptionCategory, setSelectedOptionCategory] = useState("");
  const [disableContinueBtn, setDisableContinueBtn] = useState(true);
  const [disableServiceCategorySelect, setDisableServiceCategorySelect] = useState(true);
  const [requestAnAuthRecords, setRequestAnAuthRecords] = useState<Record<string, RequestAnAuthApiResponse[]>>({});
  const [insurerOptions, setInsurerOptions] = useState<string[]>([]);
  const [serviceCategoryOptions, setServiceCategoryOptions] = useState<string[]>([]);

  useEffect(() => {
    const navLink = document.getElementById("requestAnAuthNavLink");
    if(navLink != null){
      navLink.classList.add("active");
    }
    //TODO: Display an alert when an exception occurs
    props.requestAnAuthService.getOptions()
    .then((resp)=>{
      setRequestAnAuthRecords(resp)
      setInsurerOptions(Object.keys(resp))
    })
    .catch(ex => appInsights.trackException(ex));
  },[])

  function handleInsurerChange(e: React.ChangeEvent<HTMLSelectElement>)
  {
    setSelectedOptionInsurer(e.target.value);
    setDisableServiceCategorySelect(false);
    setDisableContinueBtn(true);
    setSelectedOptionCategory("");
    setServiceCategoryOptions(requestAnAuthRecords[e.target.value].map(resp => resp.medicalDisciplineName));
  }

  function handleServiceCategoryChange(e: React.ChangeEvent<HTMLSelectElement>)
  {
    setSelectedOptionCategory(e.target.value);
    setDisableContinueBtn(false);
  }

  return (
    <div>
        <Header />               
        <main>
          <div className='container-fluid'>
            <div className='card-alt mb-3'>
              <div className='card-body pl-3'>
                <h3>Request an Authorization</h3>
                <h5 className='pt-2'>Please select an Insurer and Service Category below to build your request.</h5>
                <form>
                  <div className='row mt-3 mb-3 mr-2 ml-2'>                
                      <div className='col'>
                      <select title="Select Insurer" value={selectedOptionInsurer} onChange={handleInsurerChange} data-testid='insurerSelect' className='form-control'>
                        <option value="" disabled hidden>Select an Insurer</option>
                        {
                          insurerOptions.map(io => 
                            <option key={io} value={io}>{io}</option>
                          )
                        }
                      </select>
                      </div>
                      <div className='col'>
                      <select title="Select Service Category" value={selectedOptionCategory} onChange={handleServiceCategoryChange} data-testid='serviceCategorySelect' disabled={disableServiceCategorySelect} className='form-control'>
                        <option value="" hidden>Select Service Category</option>
                        {
                          serviceCategoryOptions.map(sco => 
                            <option key={sco} value={sco}>{sco}</option>
                          )
                        }
                      </select>
                      </div>
                  </div>
                  <div className='row text-right'>
                    <div className='col'>
                      <button disabled={disableContinueBtn} data-testid='requestAnAuthContinueBtn' className='btn btn-primary'>Continue</button>
                    </div>
                  </div>  
                </form>      
              </div>
            </div>
            <div className='alert alert-dismissible alert-info'>
              <button data-testid={'dismissAlertBtn'} type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              <p className="mb-0">The following request types are not yet available in this portal. </p>
            <ul data-testid={'ePAlertInfo'} style={{listStyleType:'circle'}}>
              <li>
                <b>Durable Medical Equipment</b> for Cigna
              </li>
              <li>
                <b>Home Health</b> for BCBSMN, Cigna or The Health Plan
              </li>
              <li>
                <b>Post Acute Care</b> for BCBSMN or The Health Plan
              </li>
              <li>
                <b>Sleep</b> for Cigna
              </li>
            </ul>     
            Please <a data-testid='epPortalHyperlink' href={process.env.ep_url} className="alert-link">log in to the eP Portal</a> to continue building your request.   
            </div>
          </div>
        </main>
  </div>
  )
}

export default RequestAnAuth;