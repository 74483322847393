// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrdedMjkLF9m2hD5duoh {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.IKLBdrc6J8EOK29_9oLT {
  color: red;
  text-align: center;
  padding: 10px;
}

.JsPZCV1de1wip9VHyB8N {
  text-align: center;
  padding: 10px;
}
.T2D1mTtV_ADckAgWXnyu {
  background-color: #035c67;
}

.uA9VlAl2RrXCtBk9rwHW {
  background-color: #008f83;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/header.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,MAAM;EACN,WAAW;EACX,aAAa;AACf;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".stickyTop {\n  position: sticky;\n  top: 0;\n  width: 100%;\n  z-index: 1000;\n}\n\n.error {\n  color: red;\n  text-align: center;\n  padding: 10px;\n}\n\n.loading {\n  text-align: center;\n  padding: 10px;\n}\n.navbarTop {\n  background-color: #035c67;\n}\n\n.navbarBottom {\n  background-color: #008f83;\n}\n"],"sourceRoot":""}]);
// Exports
export var stickyTop = `OrdedMjkLF9m2hD5duoh`;
export var error = `IKLBdrc6J8EOK29_9oLT`;
export var loading = `JsPZCV1de1wip9VHyB8N`;
export var navbarTop = `T2D1mTtV_ADckAgWXnyu`;
export var navbarBottom = `uA9VlAl2RrXCtBk9rwHW`;
export default ___CSS_LOADER_EXPORT___;
