/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useState } from 'react';
import * as styles from './sharedworklistaccessmodal.module.css';
import { createPortal } from "react-dom";
import SharedWorklistService from '../../services/sharedWorklist.service'
import { appInsights } from "../../services/appInsights.service";
import Pagination from '../Worklist/pagination.component';

interface SharedWorklistAccessModalProps {
    onClose: () => void;
}


const SharedWorklistAccessModal: React.FC<SharedWorklistAccessModalProps> = ({ onClose }) => {

    const [allUsersWithAccess, setAllUsersWithAccess] = useState<UserWithAccess[]>([]);
    const [usersWithPendingAccess, setUsersWithPendingAccess] = useState<UserWithAccess[]>([]);
    const [usersWithActiveAccess, setUsersWithActiveAccess] = useState<UserWithAccess[]>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await SharedWorklistService.fetchUsersWithAccess();
                setAllUsersWithAccess(data);
                const pendingUsers = data.filter(item => item.status === "Pending");
                setUsersWithPendingAccess(pendingUsers);
                const activeUsersWithAccess = data.filter(item => item.status !== "Pending");
                setUsersWithActiveAccess(activeUsersWithAccess);
            } catch (error) {
                if (error instanceof Error) {
                    appInsights.trackException({ exception: error });
                }
            }
        };
        fetchData();
    }, []);

    const handlePageClick = (data: { selected: number }) => {
        setCurrentPage(data.selected);
    };

    const handlePageSizeChange = (size: number) => {
        setPageSize(size);
        setCurrentPage(0);
    };
    return (
        createPortal(
            <div className={`modal pg-show-modal show ${styles.modalShow}`} id="haveaccessModal" data-testid="accessModelElement" aria-modal="true" >
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Users with access to My Worklist&nbsp;</h4>
                            <button type="button" className="close" data-dismiss="modal">×</button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="tab-pane fade show active" id="first">
                                    <div className="card my-3">

                                        <div className="card-body mb-3">
                                            <h5 className="mb-3">Pending requests to access My Worklist</h5>
                                            <table className="table table-sm">
                                                <tbody>
                                                </tbody><thead>
                                                    <tr>
                                                        <td><b>Full name</b></td>
                                                        <td className="w-15"><b>User ID</b></td>
                                                        <td><b>Email address</b></td>
                                                        <td><b>Actions</b></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {usersWithPendingAccess.map((user) => (
                                                        <tr key={user.userId}>
                                                            <td>{user.firstName} {user.lastName}</td>
                                                            <td>{user.userId}</td>
                                                            <td>{user.emailAddress}</td>
                                                            <td><a href="*" className="btn btn-sm btn-tertiary py-0 mx-1" data-toggle="modal" data-target="#removeModal"><i className="fa fa-fw fa-times-circle text-danger"></i>&nbsp;Remove</a></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <h5 className="mb-3">Users with active access to My Worklist</h5>
                                            <table className="table table-sm">
                                                <tbody>
                                                </tbody><thead>
                                                    <tr>
                                                        <td><b>Full name</b></td>
                                                        <td className="w-15"><b>User ID</b></td>
                                                        <td><b>Email address</b></td>
                                                        <td><b>Access</b></td>
                                                        <td><b>Actions</b></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {usersWithActiveAccess.map((user) => (
                                                        <tr key={user.userId}>
                                                            <td>{user.firstName} {user.lastName}</td>
                                                            <td>{user.userId}</td>
                                                            <td>{user.emailAddress}</td>
                                                            <td>
                                                                <div className="custom-control custom-switch">
                                                                    <input type="checkbox" className="custom-control-input" id="customSwitch5" />
                                                                    <label className="custom-control-label" htmlFor="customSwitch5">Access</label>
                                                                </div>
                                                            </td>
                                                            <td><a href="*" className="btn btn-sm btn-tertiary py-0 mx-1" data-toggle="modal" data-target="#removeModal"><i className="fa fa-fw fa-times-circle text-danger"></i>&nbsp;Remove</a></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <footer className={`border-top d-flex form-group form-inline justify-content-end m-0 ${styles.footerStyle}`} >
                                            <Pagination
                                                pageCount={pageCount}
                                                currentPage={currentPage}
                                                onPageChange={handlePageClick}
                                                pageSize={pageSize}
                                                onPageSizeChange={handlePageSizeChange}
                                            />
                                        </footer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>,
            document.body)
    );
};

export default SharedWorklistAccessModal;