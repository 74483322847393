import './timeoutprovider.component.css';
import { ReactNode, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { revokeSession } from '../../services/timeout.service';

export function TimeoutProvider({ children, timeoutSeconds }: { children: ReactNode, timeoutSeconds: number }) {
    const navigate = useNavigate();
    const location = useLocation();
    const timeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (exemptedRoutes.includes(location.pathname)) {
            return;
        }
        const handleWindowEvents = () => {

            clearTimeout(timeoutRef.current);

            timeoutRef.current = setTimeout(() => {

                //call api to revoke token
                revokeSession();

                navigate('/timeoutpage');
            }, timeoutSeconds * 1000);
        };

        // listen for specific window events to ensure user is still active
        window.addEventListener('mousemove', handleWindowEvents);
        window.addEventListener('keydown', handleWindowEvents);
        window.addEventListener('click', handleWindowEvents);
        window.addEventListener('scroll', handleWindowEvents);

        handleWindowEvents();

        //cleanup function
        return () => {
            window.removeEventListener('mousemove', handleWindowEvents);
            window.removeEventListener('keydown', handleWindowEvents);
            window.removeEventListener('click', handleWindowEvents);
            window.removeEventListener('scroll', handleWindowEvents);
        };
    }, [navigate, location.pathname]);

    return children;
}

const exemptedRoutes = ['/404'];