import React, { useEffect } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import ITokenService from '../../interfaces/ITokenService';

type CallBackServiceProps = {
    tokenService:ITokenService
};

//In the future this should be wrapped in a suspense component that contains a loading spinner
//We should also load some sort of backgound in
const InitiateAuthLookup: React.FC<CallBackServiceProps> = (props: CallBackServiceProps) => {
  
    //AuthId is needed when we actually redirect to CW
    async function handleRedirect(challenge:string, authId: string) {
      const accessCodeResponse = await props.tokenService.getAccessCode(challenge, authId);
      if (accessCodeResponse) {
        console.log(JSON.stringify(accessCodeResponse));
        const url = `${process.env.cw_ui_base_url}${process.env.cw_redirect_path}?code=${accessCodeResponse.accessCode}`
        window.location.replace(url);
      }
    } 

    const [searchParams] = useSearchParams();
    const challenge = searchParams.get('challenge') || '';
    const { authId = '' } = useParams();
    
    useEffect(() => {
      handleRedirect(challenge, authId);
    }, [])

    return (
        <div>Redirecting...</div>
    );
};

export default InitiateAuthLookup;
