import { IRequestAnAuthService, RequestAnAuthApiResponse } from "../interfaces/IRequestAnAuthService";

export class RequestAnAuthService implements IRequestAnAuthService {
    
  async getOptions(): Promise<Record<string, RequestAnAuthApiResponse[]>> {
    const accessToken = sessionStorage.getItem('accessToken');
    if (!accessToken) {
      throw new Error('Error retrieving Access Token');
    }
    const response = await fetch(`${process.env.api_url}/RequestAnAuth`, {
      method: 'GET',
      headers: {
        Authorization: `${accessToken}`,
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data: RequestAnAuthApiResponse[] = await response.json();

    const records:Record<string, RequestAnAuthApiResponse[]> = this.groupBy(data, data => data.organizationName);

    return records; 
    
}

// eslint-disable-next-line
groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
    }, {} as Record<K, T[]>);
}

