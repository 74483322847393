import ITokenService from "../interfaces/ITokenService";
import {appInsights} from './appInsights.service';

export class TokenService implements ITokenService {

  getAccessCode = async(challenge:string, authId: string) => {
    const accessToken = sessionStorage.getItem('accessToken');
    if (!accessToken) {
      throw new Error('Error retrieving Access Token');
    }
    try {
      const response = await fetch(`${process.env.api_url}/User/accesscode`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${accessToken}`,
        },
        body: JSON.stringify({
          challenge,
          authId
        })
      });
    
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data: AccessCodeResponse = await response.json();
      return data;
    } catch{
      throw new Error('Error Fetching Access Code');
    }
  }

  async refreshAccessToken(): Promise<void> {
    try
    {
      const accessToken = sessionStorage.getItem('accessToken');

      const response = await fetch(`${process.env.api_url}/user/session/refresh`,
      {
        method:'GET',
        headers: {
          'Content-Type':'application/json',
          Authorization: `${accessToken}`,
        }
      });

      if (response.status == 200) {   
        const responseBody = await response.text();
        sessionStorage.setItem('accessToken', responseBody);
      }
      
    }
    catch (ex)
    {
      appInsights.trackException(ex);
      return;
    }
    return;   
  }

}