import React from 'react';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import * as styles from './worklist.module.css';

interface PaginationProps {
  pageCount: number;
  onPageChange: (data: { selected: number }) => void;
  pageSize: number;
  onPageSizeChange: (size: number) => void;
  currentPage: number;
}

const Pagination: React.FC<PaginationProps> = ({
  pageCount,
  onPageChange,
  pageSize,
  onPageSizeChange,
  currentPage
}) => {
  return (
    <footer
      className={`border-top d-flex form-group form-inline justify-content-end m-0 ${styles.paginationFooter}`}
    >
      <label htmlFor="showing">Showing:</label>
      <select
        id="showing"
        name="page_length"
        aria-controls="Paginate"
        className="form-control form-control-sm ml-2"
        value={pageSize}
        onChange={(e) => onPageSizeChange(Number(e.target.value))}
      >
        <option value="10">10 Results</option>
        <option value="30">30 Results</option>
        <option value="60">60 Results</option>
        <option value="90">90 Results</option>
      </select>
      <ReactPaginate
        previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
        nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        forcePage={currentPage}
        containerClassName={'pagination pagination-sm m-3'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        activeClassName={'active'}
      />
    </footer>
  );
};

export default Pagination;
